import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { Routes, useNavigate, Route, useLocation } from 'react-router-dom';
import privateRouter from './routes/PrivateRouter';
import publicRouter from './routes/PublicRouter';
import clientRouter from './routes/ClientRouter';
import store from './store';
import NotFound from './Layout/NotFound';
import { useSelector } from 'react-redux';
import managerRouter from './routes/ManagerRouter';
import { axiosInstance } from './utils';


const replaceObjectIds = (route) => {
  return route.replace(/[a-fA-F0-9]{24}/g, "objectId");
};



const cookies = new Cookies();
let adminRoutes = [
  "/user",
  "/admin",
  "/client-list",
  "/dms-stats",
  "/vin-decoder",
  "/changepassword",
  "/file-deletion",
  "/export-jobdrop-data",
  "/client-list/settings",
  "/client-list/objectId",
  "/client-list/objectId/jobs",
  "/client-list/objectId/export",
  "/client-list/objectId/settings",
  "/client-list/objectId/clean-files",
  "/client-list/objectId/add-clean-file",
  "/client-list/objectId/ncoa",
  "/client-list/objectId/add-ncoa-file",
  "/client-list/objectId/add-re-ncoa",
  "/client-list/objectId/re-ncoa",
  "/client-list/add",
  "/client-list/objectId/jobs/objectId",
  "/client-list/objectId/jobs/latest",
  "/client-list/objectId/jobs/objectId/objectId",
  "/admin/clean-file-list/objectId/objectId",
  "/admin/job-drop-list/objectId/objectId",
  "/admin/ncoa-file-list/objectId/objectId"
]
let managerRoutes = [
  "/manager/user",
  "/manager/file-deletion",
  "/manager/client-list",
  "/manager/dms-stats",
  "/manager/client-list/settings",
  "/manager/client-list/objectId",
  "/manager/client-list/objectId/jobs",
  "/manager/client-list/objectId/export",
  "/manager/client-list/objectId/settings",
  "/manager/client-list/objectId/clean-files",
  "/manager/client-list/objectId/add-clean-file",
  "/manager/client-list/objectId/ncoa",
  "/manager/client-list/objectId/add-ncoa-file",
  "/manager/client-list/objectId/add-re-ncoa",
  "/manager/client-list/objectId/re-ncoa",
  "/manager/client-list/add",
  "/manager/client-list/objectId/jobs/objectId",
  "/manager/client-list/objectId/jobs/latest",
  "/manager/client-list/objectId/jobs/objectId/objectId",
  "/manager/clean-file-list/objectId/objectId",
  "/manager/job-drop-list/objectId/objectId",
  "/manager/ncoa-file-list/objectId/objectId"
];
let clientRoutes = [
  "/client/settings",
  "/access",
  "/client",
  "/client/objectId",
  "/client/objectId/jobs",
  "/client/objectId/export",
  "/client/objectId/settings",
  "/client/objectId/clean-files",
  "/client/objectId/add-clean-file",
  "/client/objectId/ncoa",
  "/client/objectId/add-ncoa-file",
  "/client/objectId/add-re-ncoa",
  "/client/objectId/re-ncoa",
  "/client/add",
  "/client/objectId/jobs/objectId",
  "/client/objectId/jobs/latest",
  "/client/objectId/jobs/objectId/objectId",
  "/client/clean-file-list/objectId/objectId",
  "/client/job-drop-list/objectId/objectId",
  "/client/ncoa-file-list/objectId/objectId",
  "/dms-stats/user",
  "/job-drops",
  "/ncoa-list",
  "/page1",
  "/page2"
];



function App() {
  const client = useSelector(state => state.authModel.client);
  const manager = useSelector(state => state.authModel.manager);
  const admin = useSelector(state => state.authModel.admin);



  const { dispatch } = store;
  const navigate = useNavigate();
  const location = useLocation(); // Listen for route changes
  const handleNavigate = (link) => {
    navigate(link);
  };


  useEffect(() => {
    const adminToken = cookies.get('x-auth-token');
    const clientToken = cookies.get('c-auth-token');
    const managerToken = cookies.get('m-auth-token');
    let activeRoute = replaceObjectIds(location.pathname);
    const isAdminRoute = adminRoutes.includes(activeRoute);
    const isClientRoute = clientRoutes.includes(activeRoute);
    const isManagerRoute = managerRoutes.includes(activeRoute);



    if (isManagerRoute && managerToken) {
      dispatch.authModel.getManagerDetails();
    }


    if (isAdminRoute && adminToken) {
      dispatch.authModel.getAdminDetails();
    }


    if (isClientRoute && clientToken) {
      dispatch.authModel.getClientDetails();
    }
  }, []);


  const setTableHeight = () => {
    let tableHeight = '';


    if (location.pathname.includes('file-deletion')) {
      tableHeight = window.innerHeight - (116 + 20 + document.querySelector('.file_deletion_User_Panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('jobs')) {
      tableHeight = window.innerHeight - (116 + 20 + 66 + document.querySelector('.client_sub_panel')?.offsetHeight + document.querySelector('.date_range_panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('clean-files')) {
      tableHeight = window.innerHeight - (116 + 20 + 66 + document.querySelector('.client_sub_panel')?.offsetHeight + document.querySelector('.date_range_panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('ncoa')) {
      tableHeight = window.innerHeight - (110 + 20 + 66 + document.querySelector('.client_sub_panel')?.offsetHeight + document.querySelector('.date_range_panel')?.offsetHeight + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else if (location.pathname.includes('client')) {
      tableHeight = window.innerHeight - (116 + 20 + 77 + document.querySelector('.ant-table-cell')?.offsetHeight);
    }
    else {
      tableHeight = window.innerHeight - (116 + 20 + 77 + document.querySelector('.ant-table-cell')?.offsetHeight);
    }


    document.documentElement.style.setProperty('--table-body-height', `${tableHeight}px`);
  };



  useEffect(() => {
    const updateTableHeight = () => {
      setTimeout(() => {
        requestAnimationFrame(() => {
          setTableHeight();
        });
      }, 2000); // Delays execution by 2 seconds
    };


    updateTableHeight(); // Run on mount & route change


    // Monitor DOM changes to adjust height when elements load
    const observer = new MutationObserver(updateTableHeight);
    observer.observe(document.body, { childList: true, subtree: true });


    // Window resize event
    window.addEventListener('resize', updateTableHeight);


    return () => {
      observer.disconnect(); // Cleanup observer
      window.removeEventListener('resize', updateTableHeight);
    };
  }, [location]);


  // Function to open a route in a new tab



  return (
    <>
      <Routes>
        {publicRouter(handleNavigate)}
        {privateRouter(handleNavigate, admin, "Admin")}
        {clientRouter(handleNavigate, client, "Client")}
        {managerRouter(handleNavigate, manager, "Manager")}
        <Route path="*" element={<NotFound handleNavigate={handleNavigate} />} />
      </Routes>
    </>
  );
}


export default App;