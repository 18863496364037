import axiosAdmin from '../../api/axiosAdmin';
import axiosManager from '../../api/axiosManager';
import CustomNotification from '../../components/CustomNotification';

const deletionModel = {
  state: {
    deletionList: {
      data: {},
      page: 1,
      limit: 10,
      totalDocs: 0,
      docs: []
    },
  },

  reducers: {
    setDeletionModelData(state, payload) {
      return { ...state, [payload.name]: { ...payload.data } };
    }
  },
  effects: (dispatch) => ({
    async getDeletion({ isManager, ...payload }) {
      await (isManager ? axiosManager : axiosAdmin)
        .post("/api/deletion", payload?.payload)
        .then((res) => {
          if (res.data.code === 200) {
            this.setDeletionModelData({ name: 'deletionList', data: res.data.data, });
            return res.data.data;
          } else {
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getDeletionAction({ isManager, ...payload }) {
      const { files, isDelete, fileType, handleInfoPop } = payload;
      await (isManager ? axiosManager : axiosAdmin)
        .post("/api/deletion/action", { files, isDelete, fileType })
        .then((res) => {
          if (res.data.code === 200) {
            handleInfoPop({});
            return res.data.data;
          } else {
            handleInfoPop({});
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          handleInfoPop({});
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
    async getPerminantDeleteAction({ isManager, ...payload }) {
      const { files, isDelete, fileType, handleInfoPop } = payload;
      await (isManager ? axiosManager : axiosAdmin)
        .post("/api/deletion/directDelete", { files, fileType })
        .then((res) => {
          if (res.data.code === 200) {
            handleInfoPop({});
            return res.data.data;
          } else {
            handleInfoPop({});
            CustomNotification({
              title: 'Info',
              subTitle: res.data.message,
              dispatch
            });
          }
        })
        .catch((err) => {
          handleInfoPop({});
          CustomNotification({
            title: 'Error',
            subTitle: err.message,
            dispatch
          });
        });
    },
  })
};

export default deletionModel;
