import React from "react";
import { Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import Loader from "../../Layout/Loader";
import { isManagerAuthenticated } from "../../utils";
import { ManagerRouteList } from "../data/routes";
import ManagerLayout from "../../Layout/ManagerLayout";
import CustomLayout from "../../Layout/CustomLayout";

const managerRouter = (handleNavigate, manager, role,) => {

    const PrivateRouters = ({ children }) => {
        let token = isManagerAuthenticated();
        if (!token) return <Navigate to="/" />;
        return children;
    };

    return (
        ManagerRouteList.map(({ component: Component, path, name, pageType, layout, client }, key) => {
            switch (layout) {
                case "custom": {
                    return (
                        <Route
                            path={path}
                            element={
                                <PrivateRouters>
                                    <CustomLayout handleNavigate={handleNavigate}>
                                        <React.Suspense fallback={<Loader />}>

                                            <Component handleNavigate={handleNavigate} role={role} client={manager} pageType={pageType} client={client} />


                                        </React.Suspense>
                                    </CustomLayout>
                                </PrivateRouters>
                            }
                            key={key}
                        />
                    );
                }
                default: {
                    return (
                        <Route
                            path={path}
                            element={
                                <PrivateRouters>
                                    <ManagerLayout handleNavigate={handleNavigate} title={name}>
                                        <React.Suspense fallback={<Loader />}>
                                            <Component handleNavigate={handleNavigate} client={manager} role={role} pageType={pageType}
                                            />
                                        </React.Suspense>
                                    </ManagerLayout>
                                </PrivateRouters>
                            }
                            key={key}
                        />
                    )
                }
            }

        }))
};

export default managerRouter